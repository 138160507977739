import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import GatsbyImage from 'gatsby-image'

const imageQuery = graphql`
	query HeroImage {
		file(relativePath: { eq: "hero.png" }) {
			childImageSharp {
				fluid(maxHeight: 660, quality: 100, srcSetBreakpoints: [1023, 1280]) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
	}
`

const HeroImage = () => {
	const data = useStaticQuery(imageQuery)
	return <GatsbyImage fluid={data.file.childImageSharp.fluid} alt='Uma médica dando um sorriso.' />
}

export default HeroImage
