import React, { useCallback } from 'react'
import { useCookies } from 'react-cookie'

const useWelcomeLinks = () => {
	const [cookies, setCookie, removeCookie] = useCookies()

	const medicoLink = useCallback(
		() => (cookies['medico-welcome'] ? '/assinatura?tipo=medico&nexodata=0&ezorder=0' : '/welcome-medico'),
		[cookies]
	)

	const farmaceuticoLink = useCallback(
		() => (cookies['farmaceutico-welcome'] ? '/assinatura?tipo=farmaceutico' : '/welcome-farmaceutico'),
		[cookies]
	)

	return { medicoLink, farmaceuticoLink }
}

export default useWelcomeLinks
