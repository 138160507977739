// from: https://codesandbox.io/s/iwxtp?file=/src/MenuButton.tsx:0-1835
import React from 'react'
import { motion, Transition, SVGMotionProps } from 'framer-motion'
import { useTheme } from '@chakra-ui/core'
import { CustomTheme } from '../components/customTheme'

interface Props extends SVGMotionProps<any> {
	isOpen?: boolean
	strokeWidth?: string | number
	transition?: Transition
	lineProps?: any
}

const HamburgerButton = ({
	isOpen = false,
	width = 24,
	height = 14,
	strokeWidth = 2,
	transition = null,
	lineProps = null,
	...props
}: Props) => {
	const theme = useTheme() as CustomTheme

	const variant = isOpen ? 'opened' : 'closed'
	const top = {
		closed: {
			rotate: 0,
			translateY: 0
		},
		opened: {
			rotate: 45,
			translateY: 2
		}
	}
	const center = {
		closed: {
			opacity: 1
		},
		opened: {
			opacity: 0
		}
	}
	const bottom = {
		closed: {
			rotate: 0,
			translateY: 0
		},
		opened: {
			rotate: -45,
			translateY: -2
		}
	}
	lineProps = {
		stroke: theme.colors.gray[900],
		strokeWidth: strokeWidth as number,
		vectorEffect: 'non-scaling-stroke',
		initial: 'closed',
		animate: variant,
		transition,
		...lineProps
	}
	const unitHeight = 4
	const unitWidth = (unitHeight * (width as number)) / (height as number)

	return (
		<motion.svg
			viewBox={`0 0 ${unitWidth} ${unitHeight}`}
			overflow='visible'
			preserveAspectRatio='none'
			width={width}
			height={height}
			{...props}
		>
			<motion.line x1='0' x2={unitWidth} y1='0' y2='0' variants={top} {...lineProps} />
			<motion.line x1={isOpen ? '0' : '2'} x2={unitWidth} y1='2' y2='2' variants={center} {...lineProps} />
			<motion.line x1={isOpen ? '0' : '2'} x2={unitWidth} y1='4' y2='4' variants={bottom} {...lineProps} />
		</motion.svg>
	)
}

export default HamburgerButton
