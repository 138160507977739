import * as React from 'react'
import { useTheme } from '@chakra-ui/core'
import { CustomTheme } from '../components/customTheme'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
	const theme = useTheme() as CustomTheme

	return (
		<svg width={26} height={18} viewBox='0 0 26 18' fill='none' {...props}>
			<path
				d='M1 1h24M5 9h20M5 17h20'
				stroke={theme.colors.gray[900]}
				strokeWidth={2}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	)
}

const MenuIcon = React.memo(SvgComponent)
export default MenuIcon
