import React, { useEffect, useState } from 'react'
import {
	Box,
	Flex,
	Text,
	Stack,
	Drawer,
	DrawerOverlay,
	DrawerContent,
	DrawerHeader,
	DrawerBody,
	useDisclosure,
	Icon,
	Button,
	Link as ChakraLink
} from '@chakra-ui/core'

import MenuIcon from './MenuIcon'
import styled from '@emotion/styled'
import HeaderItem from './HeaderItem'

import scrollTo from 'gatsby-plugin-smoothscroll'

import { theme } from 'styled-tools'
import HamburgerButton from './HamburgerButton'
import Logo from './Logo'
import { Link } from 'gatsby'
import useWelcomeLinks from '../hooks/useWelcomeLinks'
import Hero from './Hero'

const FadeInDrawerContent = styled(DrawerContent)`
	transform: none !important;

 	/* TODO: Animação de fade-in-out n funcionou assim. Pesquisar uma lib no futuro pra fazer isso */
	/* -webkit-transition: opacity 1s ease-in-out;
	-moz-transition: opacity 1s ease-in-out;
	-ms-transition: opacity 1s ease-in-out;
	-o-transition: opacity 1s ease-in-out;
	transition: opacity 1s ease-in-out;

	opacity: ${props => (props.isOpen ? '1' : '0')}; */
`

const DrawerText = styled(Text)`
	&:hover,
	:active {
		color: ${theme('colors.brand.500')};
	}

	display: block;
	cursor: pointer;

	line-height: 3.5;
	font-size: ${theme('fontSizes.lg')};
`

const Header: React.FunctionComponent = () => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const { farmaceuticoLink, medicoLink } = useWelcomeLinks()

	const [buttonColors, setButtonColors] = useState({ bg: 'brand.500', text: 'white' })

	useEffect(() => {
		const interval = setInterval(() => {
			setButtonColors({ bg: 'white', text: 'brand.500' })
			setTimeout(() => setButtonColors({ bg: 'brand.500', text: 'white' }), 300)
		}, 15000)
		return () => clearInterval(interval)
	}, [])

	return (
		<>
			<Box h='60px' boxShadow='lg' px={4} mt={0} bg='white' pos='fixed' w='100vw' maxW='100%' zIndex={10}>
				<Flex justifyContent='space-between' align='center' bg='white' h='60px' maxW='1280px' mx='auto'>
					<Logo />
					<Icon
						cursor='pointer'
						as={MenuIcon}
						size='24px'
						display={['block', 'none']}
						aria-label='Botão para abrir o menu'
						onClick={onOpen}
					/>

					<Drawer onClose={onClose} isOpen={isOpen} size='full' isFullHeight>
						<DrawerOverlay />
						<FadeInDrawerContent>
							<DrawerHeader p={0}>
								<Flex h='60px' px={4} py={0} justifyContent='space-between' align='center'>
									<Logo />
									{/* Acabei fazendo a cópia do header de fora no DrawerHeader. Acho que dá pra melhorar */}
									<Box tabIndex={0} aria-label='Menu' role='button' aria-controls='navigation'>
										<HamburgerButton isOpen={isOpen} onClick={onClose} />
									</Box>
								</Flex>
							</DrawerHeader>

							<DrawerBody>
								<Stack spacing='8' as={'nav'}>
									<Box as={'ul'} listStyleType='none'>
										<DrawerText
											as={Link}
											to={medicoLink()}
											onClick={() => {
												onClose()
											}}
										>
											Prescrição médica
										</DrawerText>
										<DrawerText
											as={Link}
											to={farmaceuticoLink()}
											onClick={() => {
												onClose()
											}}
										>
											Dispensação Farmacêutica
										</DrawerText>

										<DrawerText
											// textTransform='uppercase'
											onClick={() => {
												scrollTo(`#pacientes`)
												onClose()
											}}
										>
											Pacientes
										</DrawerText>
									</Box>
									<ChakraLink href='https://vidaas.com.br' target='_blank' rel='noopener'>
										<Button variantColor='brand' rounded='20px' fontFamily='Poppins' fontWeight='500'>
											Adquira o seu VIDaaS
										</Button>
									</ChakraLink>
								</Stack>
							</DrawerBody>
						</FadeInDrawerContent>
					</Drawer>

					<Stack as={'nav'} isInline spacing={8} alignItems='center' display={['none', 'flex']} h='100%'>
						<Box as={'ul'} listStyleType='none' textAlign='center' h='100%'>
							<HeaderItem displayText='Prescrição médica' type='navigate' to={medicoLink()} />
							<HeaderItem displayText='Dispensação Farmacêutica' type='navigate' to={farmaceuticoLink()} />
							<HeaderItem displayText='Pacientes' type='scroll' to='pacientes' />
							<ChakraLink
								href='https://vidaas.com.br'
								target='_blank'
								rel='noopener'
								_hover={{ textDecoration: 'none' }}
							>
								{/* <Button size='sm' variantColor='brand' rounded='20px' fontFamily='Poppins' fontWeight='500'>
									Certificado Digital GRÁTIS
								</Button> */}
								<Button
									size='sm'
									// variantColor='brand'
									bg={buttonColors.bg}
									color={buttonColors.text}
									rounded='20px'
									fontFamily='Poppins'
									fontWeight='500'
									_hover={{
										backgroundColor: 'white',
										color: 'brand.500'
									}}
								>
									Adquira o seu VIDaaS
								</Button>
							</ChakraLink>
						</Box>
					</Stack>
				</Flex>
			</Box>
			<Hero />
		</>
	)
}

export default Header
