import React from 'react'
import Popup from 'reactjs-popup'
import PoliticaPrivacidadeText from './PoliticaPrivacidadeText'
import { Text, Box } from '@chakra-ui/core'
import styled from '@emotion/styled'

const StyledPopup = styled(Popup)`
	.popup-content {
		width: 70% !important;
	}
`

const PoliticaPrivacidade = () => {
	return (
		<StyledPopup
			trigger={
				<Text as='span' cursor='pointer' color='gray.900'>
					{' '}
					Política de Privacidade{' '}
				</Text>
			}
			modal
			closeOnDocumentClick
		>
			<Box px={[2, 8]} maxH='98vh' overflowY='auto' pt={8}>
				<PoliticaPrivacidadeText />
			</Box>
		</StyledPopup>
	)
}

export default PoliticaPrivacidade
