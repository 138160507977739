import React from 'react'
import styled from '@emotion/styled'

import { theme } from 'styled-tools'
import { Text } from '@chakra-ui/core'
import { Link } from 'gatsby'

import scrollTo from 'gatsby-plugin-smoothscroll'

const HeaderLi = styled.li`
	position: relative;
	display: inline-flex;
	height: 100%;

	&:not(:last-child) {
		margin-right: ${theme('space.10')};
	}

	transition: all 0.5s;

	&::before {
		transition: all 0.5s;
	}

	&::after {
		transition: all 0.5s;
	}

	&::after {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		width: 0%;
		content: '.';
		color: transparent;
		height: 3px;
		background: linear-gradient(135deg, ${theme('colors.brand.400')}, ${theme('colors.brand.700')});
	}

	&:hover {
		&::after {
			width: 50%;
		}
	}
`

const HeaderText = styled(Text)`
	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;

	/* preguiça de escrever as props do styled-system, colei oq tinha no figma aqui */
	font-family: 'Poppins';
	font-size: 14px;
	font-weight: 500;
	line-height: 21px;
	line-height: 21px;
	letter-spacing: 0.1px;

	text-decoration: none;
`

interface NavbarItemProps {
	displayText: string
	type: 'navigate' | 'scroll'
	to: string
}

const HeaderItem = ({ displayText, type, to }: NavbarItemProps) => {
	return (
		<HeaderLi>
			<HeaderText
				as={type === 'navigate' ? Link : Text}
				// textTransform='uppercase'
				color='gray.900'
				fontFamily='Poppins'
				fontSize='sm'
				to={to}
				onClick={() => type === 'scroll' && scrollTo(`#${to}`)}
			>
				{displayText}
			</HeaderText>
		</HeaderLi>
	)
}

export default HeaderItem
