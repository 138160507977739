import React from 'react'

import Layout from '../components/layout'
import { graphql } from 'gatsby'

import Header from '../Header/Header'

import { IndexQuery } from '../generated/graphql'
import useWelcomeLinks from '../hooks/useWelcomeLinks'
import Footer from '../components/Footer'
import SectionOne from '../SectionOne/SectionOne'
import SectionTwo from '../SectionTwo/SectionTwo'
import MaintananceNotice from '../components/MaintananceNotice'

interface IndexProps {
	data: IndexQuery
}

const IndexPage: React.FC<IndexProps> = ({ data }) => {
	const { farmaceuticoLink, medicoLink } = useWelcomeLinks()

	return (
		<Layout>
			{/* <MaintananceNotice date='07/10' hour='22h às 24h' /> */}
			<Header />
			<SectionOne />
			<SectionTwo />
			<Footer />
		</Layout>
	)
}

export const pageQuery = graphql`
	query Index {
		exemplo: file(relativePath: { eq: "validacao-digital.png" }) {
			childImageSharp {
				fixed(width: 312, height: 210) {
					...GatsbyImageSharpFixed_withWebp_tracedSVG
				}
			}
		}
	}
`

export default IndexPage
