import React from 'react'
import { Text } from '@chakra-ui/core'

const Logo = () => (
	<Text mr={[0, 0]} fontFamily='Poppins' fontSize='md' fontWeight='bold'>
		receitamedica.com.vc
	</Text>
)

export default Logo
