import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import styled from '@emotion/styled'
import Blob from './Blob'
import { Box } from '@chakra-ui/core'
import ParallaxItemHorizontal, { handleScroll } from './ParallaxItemHorizontal'

const imageQuery = graphql`
	query FarmaceuticoImage {
		file(relativePath: { eq: "farmaceutico.png" }) {
			childImageSharp {
				fluid(maxHeight: 800, quality: 100, srcSetBreakpoints: [1023, 1280]) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
	}
`

const StyledGatsbyImage = styled(GatsbyImage)`
	width: 80%;
	margin: 0 auto;

	@media (min-width: 1023px) {
		width: 100%;
		margin: 0;
	}

	max-height: 520px;
	z-index: 2;
`

const FarmaceuticoImage = () => {
	const data = useStaticQuery(imageQuery)

	const [scroll, setScroll] = useState((typeof window !== 'undefined' && window.innerHeight) || 0)

	React.useEffect(() => {
		if (typeof window !== 'undefined') {
			window.addEventListener(
				'scroll',
				handleScroll(() => {
					setScroll(window.pageYOffset + window.innerHeight)
				})
			)
		}
	}, [])

	return (
		<Box pos='relative' overflow='hidden' maxH='650px'>
			<StyledGatsbyImage
				fluid={data.file.childImageSharp.fluid}
				alt='Uma farmacêutica olhando para um computador em cima do balcão'
			/>
			<ParallaxItemHorizontal scroll={scroll} ltr debug='farm'>
				<Blob left='-300px' />
			</ParallaxItemHorizontal>
		</Box>
	)
}

export default FarmaceuticoImage
