import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import styled from '@emotion/styled'

const imageQuery = graphql`
	query Blob {
		file(relativePath: { eq: "blob.png" }) {
			childImageSharp {
				fluid(maxHeight: 500, quality: 100, srcSetBreakpoints: [1023, 1280]) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
	}
`

const StyledGatsbyImage = styled(GatsbyImage)`
	display: none;

	@media (min-width: 1023px) {
		display: block;
		width: 100%;
		margin: 0;
	}

	max-height: 520px;
`

interface BlobProps {
	left?: string
	right?: string
}

const Blob: React.FC<BlobProps> = ({ left, right }) => {
	const data = useStaticQuery(imageQuery)
	return (
		<StyledGatsbyImage
			fluid={data.file.childImageSharp.fluid}
			alt='Uma farmacêutica olhando para um computador em cima do balcão'
			imgStyle={{
				objectPosition: 'bottom'
			}}
			style={{
				top: '-350px',
				zIndex: -1,
				filter: 'brightness( 100% ) contrast( 100% ) saturate( 100% ) blur( 0px ) hue-rotate( 287deg )', // lulz
				left: left,
				right: right
			}}
		/>
	)
}

export default Blob
