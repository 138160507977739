import React from 'react'
import { Box, Flex, Text, Grid, Link } from '@chakra-ui/core'
import Logo from '../Header/Logo'
import PoliticaPrivacidade from './PoliticaPrivacidade'
import Termos from './Termos'

const Footer = () => {
	return (
		<Box
			minH='60px'
			boxShadow='0 -4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06)'
			px={4}
			bg='white'
			py={4}
			// mt={32}
		>
			<Grid gap={4} templateColumns={['1fr', '200px 1fr']} bg='white' minH='60px' maxW='1280px' mx='auto'>
				<Flex align='center'>
					<Logo />
				</Flex>{' '}
				<Flex direction='column' textAlign={['left', 'right']}>
					<Text fontSize='xs' color='gray.700'>
						<Link
							href='https://ajuda.validcertificadora.com.br/vidaas/receita-medica-com-vc'
							target='_blank'
							rel='noreferrer noopener'
							color='gray.900'
						>
							Ajuda
						</Link>{' '}
						– <PoliticaPrivacidade /> – <Termos />
					</Text>
					<br />
					<Text fontSize='xs' color='gray.700'>
						* Requer uso de certificado digital em nuvem emitido pela VALID. Se você não tem certificado digital em
						nuvem da Valid,{' '}
						<Link href='https://www.validcertificadora.com.br' target='_blank' rel='noopener' color='gray.900'>
							Visite nosso site
						</Link>{' '}
						e adquira o seu! © 2020, VALID.
					</Text>
				</Flex>
			</Grid>
		</Box>
	)
}

export default Footer
