import React from 'react'
import { Text, Flex } from '@chakra-ui/core'
import GatsbyImage from 'gatsby-image'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import { theme } from 'styled-tools'

interface CardProps {
	imageFluid: any // Couldn't type this without raising an error :(
	title: string
	subtitle: string
	action: React.ReactElement
}

const GatsbyImageRounded = styled(GatsbyImage)`
	border: 1px solid transparent;
	border-radius: 10px;
`

const CardDiv = styled(motion.div)`
	border: 1px solid transparent;
	border-radius: 5px;

	&:hover {
		box-shadow: ${theme('shadows.md')};
	}

	padding: ${theme('space.4')};

	width: 320px;
	height: 480px;

	@media (min-width: 640px) {
		&:first-child {
			margin-right: ${theme('space.8')};
		}
	}

	@media (min-width: 1023px) {
		width: 31%;
		/* Can't apply Stack props in motion.div, doing it manually here */
		&:not(:last-child) {
			margin-right: ${theme('space.8')};
		}

		margin-bottom: 0;
	}

	margin-bottom: ${theme('space.8')};
`

const Card: React.FC<CardProps> = ({ imageFluid, title, subtitle, action, ...props }) => {
	return (
		<CardDiv
			{...props}
			// whileTap={{ scale: 0.95 }}
		>
			<Flex direction='column' h='100%'>
				<motion.div whileHover={{ translateY: -10 }}>
					<GatsbyImageRounded fluid={imageFluid} alt='' />
				</motion.div>
				<Text fontSize='xl' color='brand.600' fontFamily='Poppins' fontWeight='bold' pt={4}>
					{title}
				</Text>
				<Text color='gray.800' pt={4}>
					{subtitle}
				</Text>
				<Flex mt='auto' justify='flex-end'>
					{action}
				</Flex>
			</Flex>
		</CardDiv>
	)
}

export default Card
