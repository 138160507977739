import React from 'react'
import Popup from 'reactjs-popup'
import TermosText from './TermosText'
import { Text, Box } from '@chakra-ui/core'
import styled from '@emotion/styled'

const StyledPopup = styled(Popup)`
	.popup-content {
		width: 70% !important;
	}
`
const Termos = () => {
	return (
		<StyledPopup
			trigger={
				<Text as='span' cursor='pointer' color='gray.900'>
					Termo de consentimento para tratamento de dados pessoais{' '}
				</Text>
			}
			modal
			closeOnDocumentClick
		>
			<Box px={[2, 8]} maxH='98vh' overflowY='auto' pt={8}>
				<TermosText />
			</Box>
		</StyledPopup>
	)
}

export default Termos
