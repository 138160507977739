// see: https://codesandbox.io/s/5tgfu?file=/src/index.tsx:212-405
import React from 'react'
import { motion } from 'framer-motion'

import _throttle from 'lodash.throttle'

const applyOpacity = (y: number) => {
	if (y > 1000) return 1

	const factor = y / 1000

	if (y < 0 && y > -1000) return factor * -1

	return factor
}

export const handleScroll = (cb: any) => _throttle(cb, 100)
export const applyParallax = (scroll, y, amount) => {
	if (scroll > y) {
		const value = (scroll - y) * amount
		return value
	}

	return 0
}

const ParallaxItemHorizontal = props => {
	const { scroll, ...rest } = props

	let speed = props.speed || -0.1

	const [y, setY] = React.useState(0)
	const el = React.useRef()

	React.useEffect(() => {
		if (el) {
			if (typeof window !== 'undefined') {
				window.addEventListener(
					'scroll',
					handleScroll(() => {
						setY(el.current.getBoundingClientRect().top)
					})
				)
			}
		}
	}, [])

	return (
		<motion.div
			ref={el}
			initial={{ opacity: 1 }}
			animate={{
				// opacity: y > 1000 || y < 0 ? 1 : y / 1000,
				opacity: applyOpacity(y),
				x: props.ltr ? -applyParallax(scroll, y, speed) : applyParallax(scroll, y, speed)
			}}
			transition={{ duration: 0.5 }}
			style={{
				zIndex: -1
			}}
			{...rest}
		/>
	)
}

export default ParallaxItemHorizontal
