import React from 'react'
import { Text, Flex, Button } from '@chakra-ui/core'
import Card from './Card'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { SectionOneQuery } from '../generated/graphql'

import { FaArrowCircleRight, FaArrowCircleDown } from 'react-icons/fa'
import useWelcomeLinks from '../hooks/useWelcomeLinks'

import scrollTo from 'gatsby-plugin-smoothscroll'
import { Reveal } from 'react-genie'

const imagesQuery = graphql`
	query SectionOne {
		medico: file(relativePath: { eq: "medico.png" }) {
			childImageSharp {
				fluid(maxWidth: 350, quality: 100, srcSetBreakpoints: [1023, 1280]) {
					...GatsbyImageSharpFluid_withWebp_tracedSVG
				}
			}
		}
		farmaceutico: file(relativePath: { eq: "farmaceutico.png" }) {
			childImageSharp {
				fluid(maxWidth: 350, quality: 100, srcSetBreakpoints: [1023, 1280]) {
					...GatsbyImageSharpFluid_withWebp_tracedSVG
				}
			}
		}
		paciente: file(relativePath: { eq: "paciente.png" }) {
			childImageSharp {
				fluid(maxWidth: 350, quality: 100, srcSetBreakpoints: [1023, 1280]) {
					...GatsbyImageSharpFluid_withWebp_tracedSVG
				}
			}
		}
	}
`

const SectionOne = () => {
	const data: SectionOneQuery = useStaticQuery(imagesQuery)
	const { farmaceuticoLink, medicoLink } = useWelcomeLinks()

	return (
		<Flex
			direction='column'
			align={['center', 'flex-start']}
			as='section'
			maxW='1280px'
			mx='auto'
			py={8}
			px={[4, 4, 0]}
		>
			<Reveal>
				<Flex direction='column' textAlign='center' align='center'>
					<Text color='gray.900' fontSize='4xl' fontWeight='bold'>
						Criado para facilitar a prescrição eletrônica em telessaúde
					</Text>
					<Text color='gray.800' fontSize='lg' pt={8} w='85%'>
						É mais agilidade, segurança e eficiência. Tudo com validade jurídica usando o VIDaaS, certificado digital em
						nuvem Valid, sem sair do consultório ou de casa.
					</Text>
				</Flex>
			</Reveal>

			<Reveal>
				<Flex flexWrap='wrap' align='center' justify='center' w='100%' pt={8} id='cards'>
					<Card
						imageFluid={data.medico?.childImageSharp?.fluid}
						title='Prescrição Médica'
						subtitle='Emita receita simples, especiais, antimicrobianas e atestados individuais de forma digital com certificado em
        nuvem Valid.'
						action={
							<Button
								size='lg'
								variant='link'
								variantColor='brand'
								rightIcon={FaArrowCircleRight}
								_hover={{
									textDecoration: 'none',
									color: 'brand.700'
								}}
								as={Link}
								to={medicoLink()}
								// onClick={() => navigate(medicoLink())}
							>
								Acessar agora
							</Button>
						}
					/>

					<Card
						imageFluid={data.farmaceutico?.childImageSharp?.fluid}
						title='Dispensação Farmacêutica'
						subtitle='Dispense os medicamentos das prescrições eletrônicas individuais assinadas com certificado em nuvem Valid.'
						action={
							<Button
								size='lg'
								variant='link'
								variantColor='brand'
								rightIcon={FaArrowCircleRight}
								_hover={{
									textDecoration: 'none',
									color: 'brand.700'
								}}
								as={Link}
								to={farmaceuticoLink()}
								// onClick={() => navigate(farmaceuticoLink())}
							>
								Acessar agora
							</Button>
						}
					/>
					<Card
						imageFluid={data.paciente?.childImageSharp?.fluid}
						title='Pacientes'
						subtitle='Envie suas receitas médicas digitais para a farmácia de sua preferência e garanta agilidade na cotação e entrega dos seus medicamentos.'
						action={
							<Button
								size='lg'
								variant='link'
								variantColor='brand'
								rightIcon={FaArrowCircleDown}
								_hover={{
									textDecoration: 'none',
									color: 'brand.700'
								}}
								onClick={() => scrollTo(`#pacientes`)}
							>
								Saiba mais
							</Button>
						}
					/>
				</Flex>
			</Reveal>
		</Flex>
	)
}

export default SectionOne
