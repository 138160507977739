import React from 'react'
import { Reveal } from 'react-genie'
import { Flex, Grid, Stack, Text, Button, Link } from '@chakra-ui/core'
import MedicoImage from './MedicoImage'
import useWelcomeLinks from '../hooks/useWelcomeLinks'
import { Link as GatsbyLink } from 'gatsby'
import FarmaceuticoImage from './FarmaceuticoImage'
import PacienteImage from './PacienteImage'

const SectionTwo = () => {
	const { farmaceuticoLink, medicoLink } = useWelcomeLinks()

	return (
		<Flex direction='column' as='section' py={8} px={[4, 4, 0]} textAlign={['center', 'left']}>
			<Grid templateColumns={['1fr', '1fr 1fr']} py={2} gap={8} maxH={['unset', '750px']}>
				<Flex direction='column' align={['center', 'flex-start']} ml={[0, 'max(calc((100vw - 1280px)/2), 2rem)']}>
					<Reveal>
						<Text color='gray.500' fontSize='sm' textTransform='uppercase' letterSpacing='3px'>
							REceita médica com você
						</Text>
						<Text color='gray.900' fontSize='6xl' fontWeight='600' fontFamily='Poppins' pt={4}>
							Médico
						</Text>
					</Reveal>
					<Reveal delay={200}>
						<Text color='gray.800' pt={8} w='80%' mx={['auto', 0]}>
							Acesse e comece a usar. <strong>É grátis!</strong>
							<br />
							<br />
							Ao acessar o sistema com o seu VIDaaS, complete o seu cadastro Médico e inicie as prescrições em
							receituário simples, especial ou antimicrobiano agora.
							<br />
							<br />
							Você também pode pesquisar entre as prescrições realizadas.
							<br />
							<br />
							Em seguida, basta enviar a prescrição eletrônica, diretamente para o seu paciente, já assinada, com a
							facilidade, a segurança e a agilidade que você precisa.
						</Text>
						<Button
							fontWeight='500'
							variantColor='brand'
							rounded='20px'
							fontFamily='Poppins'
							mt={12}
							as={GatsbyLink}
							to={medicoLink()}
							px={8}
						>
							Acesse agora
						</Button>
					</Reveal>
				</Flex>
				<MedicoImage />
			</Grid>
			<Reveal>
				<Grid templateColumns={['1fr', '1fr 1fr']} py={2} gap={8} maxH={['unset', '750px']}>
					<FarmaceuticoImage />
					<Flex
						direction='column'
						align={['center', 'flex-start']}
						gridRow={[1, 'auto']}
						ml={[0, 2, 8]}
						mr={[0, 'max(calc((100vw - 1280px)/2), 2rem)']}
					>
						<Reveal>
							<Text color='gray.500' fontSize='sm' textTransform='uppercase' letterSpacing='3px'>
								REceita médica com você
							</Text>
							<Text color='gray.900' fontSize='6xl' wordBreak='break-word' fontWeight='600' fontFamily='Poppins' pt={4}>
								Farmacêutico
							</Text>
						</Reveal>
						<Reveal delay={200}>
							<Text color='gray.800' pt={8} w='80%' mx={['auto', 0]}>
								Serviço gratuito para farmacêuticos. Ao receber uma prescrição eletrônica assinada digitalmente, realize
								a dispensação dos medicamentos imediatamente. <br />
								<br />
								O sistema permite que você gerencie tudo online. Você também pode escolher entre a gestão das receitas
								com o seu VIDaaS ou o download para assinatura com outros certificados.
								<br />
								<br />É o controle do seu receituário individual para a dispensação com assinatura digital.
							</Text>
							<Grid templateColumns={['220px', '220px 220px']} gap={[4, 10]} pt={8} w={['220px', '100%']} mx='auto'>
								<GatsbyLink to={farmaceuticoLink()}>
									<Button
										fontWeight='500'
										variantColor='brand'
										rounded='20px'
										fontFamily='Poppins'
										as={GatsbyLink}
										px={8}
									>
										Acesse agora
									</Button>
								</GatsbyLink>
								<Link href='https://vidaas.com.br/' target='_blank' rel='noopener' _hover={{ textDecoration: 'none' }}>
									<Button
										fontWeight='500'
										variantColor='brand'
										rounded='20px'
										fontFamily='Poppins'
										px={8}
										textDecoration='none'
									>
										Adquirir o VIDaaS
									</Button>
								</Link>
							</Grid>
						</Reveal>
					</Flex>
				</Grid>
			</Reveal>
			<Grid templateColumns={['1fr', '1fr 1fr']} py={2} gap={8} maxH={['unset', '750px']} id='pacientes'>
				<Flex direction='column' align={['center', 'flex-start']} ml={[0, 'max(calc((100vw - 1280px)/2), 2rem)']}>
					<Reveal>
						<Text color='gray.500' fontSize='sm' textTransform='uppercase' letterSpacing='3px'>
							REceita médica com você
						</Text>
						<Text color='gray.900' fontSize='6xl' fontWeight='600' fontFamily='Poppins' pt={4}>
							Paciente
						</Text>
					</Reveal>
					<Reveal delay={200}>
						<Text color='gray.800' pt={8} w='80%' mx={['auto', 0]}>
							Recebeu uma prescrição eletrônica? Encaminhe para o seu Farmacêutico diretamente pelo e-mail recebido. .
							<br />
							<br />
							Caso queira conferir se o documento é original, a assinatura digital é válida ou se ainda, o médico que
							assinou está apto para exercer a medicina. Acesse e valide agora a sua receita.
							<br />
							<br />
							Você acessa o sistema, anexa o arquivo em PDF e clique em validar.
						</Text>
						<Link
							href='https://assinaturadigital.iti.gov.br/paciente/'
							target='_blank'
							rel='noopener nofollow'
							_hover={{ textDecoration: 'none' }}
						>
							<Button fontWeight='500' variantColor='brand' rounded='20px' fontFamily='Poppins' mt={12} px={8}>
								Validação
							</Button>
						</Link>
					</Reveal>
				</Flex>
				<PacienteImage />
			</Grid>
		</Flex>
	)
}

export default SectionTwo
