import React from 'react'
import styled from '@emotion/styled'
import { Box, Grid, Text, Button, Flex } from '@chakra-ui/core'
import { Reveal, Animation } from 'react-genie'

import HeroImage from './HeroImage'
import { FaArrowCircleDown } from 'react-icons/fa'
import scrollTo from 'gatsby-plugin-smoothscroll'

const BgGradient = styled(Box)`
	background-image: linear-gradient(90deg, #daf4ff 0%, #00a3ce 100%);
`

const Hero = () => {
	return (
		<BgGradient pt={16} id='top'>
			<Grid
				gap={4}
				templateColumns={['1fr', '1fr 1.5fr']}
				// templateRows={['1fr 300px', '1fr']}
				as='section'
				maxW='1280px'
				mx='auto'
				px={[4, 4, 0]}
				py={[8, 0]}
			>
				<Reveal animation={Animation.FadeInUp}>
					<Flex direction='column' textAlign={['center', 'left']} align={['center', 'flex-start']} mt={[0, 8, 16]}>
						<Text fontSize='6xl' fontFamily='Poppins' fontWeight='bold' color='gray.900' lineHeight='1.2em'>
							Receita Médica com Você
						</Text>
						<Text fontSize='lg' color='gray.800' mt={4} w={['85%', '100%']}>
							Receituário sem a necessidade de download de arquivos, com mais rapidez, autonomia, controle e segurança
							na telemedicina.
							<br />
							<br />
							Prescreva e assine receituários online Simples, de Controle Especial ou Antimicrobianos, tudo de acordo
							com as regras do Conselho Federal de Medicina e Anvisa.
						</Text>
						<Button
							size='lg'
							variant='link'
							color='gray.800'
							rightIcon={FaArrowCircleDown}
							_hover={{
								textDecoration: 'none',
								color: 'gray.900'
							}}
							fontSize='2xl'
							mt={12}
							onClick={() => scrollTo(`#cards`)}
						>
							Saiba mais
						</Button>
					</Flex>
				</Reveal>
				<Box display={['none', 'block']}>
					<HeroImage />
				</Box>
			</Grid>
		</BgGradient>
	)
}

export default Hero
